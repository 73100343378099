<template>
  <b-overlay :show="loading">
    <b-card header="Aggiungi Gruppo">
      <b-card-body>
        <validation-observer ref="rules">
          <b-form @submit.prevent="">

            <b-row>
              <b-col cols="12">

                <cs-validation
                  label="Nome"
                  rules="required"
                >
                  <template slot-scope="props">
                    <cs-input
                      id="description"
                      v-model="model.nome"
                      label="Nome"
                      :state="props.errors.length === 0 ? null : false"
                    />
                  </template>

                </cs-validation>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">

                <cs-select
                  id="users"
                  v-model="model.utenti"
                  label="Utenti"
                  :options="options"
                  multiple
                />

              </b-col>
            </b-row>

          </b-form>
        </validation-observer>
      </b-card-body>
      <b-card-footer>
        <cs-submit-button :click="add">
          Aggiungi
        </cs-submit-button>
      </b-card-footer>
    </b-card>
  </b-overlay>
</template>

<script>

export default {
  data() {
    return {
      loading: true,
      model: {
        id: '00000000-0000-0000-0000-000000000000',
        nome: null,
        utenti: [],
      },
      options: [],
    }
  },
  created() {
    this.$remote.utenti.all()
      .then(res => { this.options = res.data.map(a => ({ id: a.id, label: a.email })) })
      .catch(err => this.showReposonseMessage(err))
      .finally(() => { this.loading = false })
  },
  methods: {
    add() {
      this.$refs.rules.validate()
        .then(success => {
          if (!success) return

          this.loading = true
          const canView = this.$grants.GRUPPI_VISUALIZZA.can

          this.$remote.gruppi.create(this.model)
            .then(result => {
              if (canView) this.$routing.GRUPPI_UTENTI_ELENCO.push({ id: result.data.id })
              else this.showAddSuccessMessage()
            })
            .catch(res => this.showReposonseMessage(res))
            .finally(() => { this.loading = false })
        })
    },
  },
}
</script>
